/**
 * This file is part of Keith-mifsud.me
 *
 * @licence Please view the Licence file supplied with this source code.
 *
 * @author Keith Mifsud <https://www.keith-mifsud.me>
 *
 * @copyright Keith Mifsud 2019 <mifsud.k@gmail.com>
 *
 * @since   2.0
 * @version 2.0 Second Major Release
 */

import React, { Component } from 'react'

import DefaultLayout from '../layouts/Default'
import SEO from '../components/Seo'
import { Link } from 'gatsby'
import Hero from '../components/Hero'
import FeaturedServices from '../components/FeaturedServices'
import FeaturedClients from '../components/FeaturedClients'
import Testimonials from '../components/Testimonials'
import Stack from '../components/Stack'
import RecentArticles from '../components/Articles/Recent'
import FeaturedProject from '../components/Projects/Featured'
import config from './../data/SiteConfig'

/**
 * The home page.
 */
class IndexPage extends Component {
  render () {
    return (
      <DefaultLayout className="page-home">
        <SEO
          pageTitle={`Software Engineer`}
          fullPageTitle={config.siteTitleAlt}
          pageDescription={config.metaDescription}
          pageImage={config.siteImage}
          pagePath={``}
          pageType={`website`}
        />
        <section className="home-top-banner" style={{ minHeight: '100vh' }}>
          <Hero className="home-hero"/>
        </section>
        <section
          className="home-services pt-lg-12"
          id={`start-here`}
          style={{ minHeight: '100vh' }}
        >
          <FeaturedServices/>
          <div className="container">
            <div className="row justify-content-center pt-lg-5">
              <div>
                <Link
                  to={`/contact`}
                  title={`Hire a full-stack Developer`}
                  className={`btn btn-large btn-warning text-white`}
                >
                  <strong>Let's work together</strong>
                </Link>
              </div>
            </div>
          </div>
        </section>
        <div className="row no-gutters" style={{ minHeight: '100vh' }}>
          <div className="bg-light w-100">
            <div className="container pt-9 pt-lg-10">
              <div className="row mb-lg-6">
                <div className="col-12 col-lg-5">
                  <h2>Recent clients</h2>
                </div>
              </div>
            </div>
          </div>
          <section
            className="home-clients col-12 order-2 order-md-1 pt-lg-0 pt-5 pb-lg-0 pb-5"
          >
            <Testimonials className={`pb-lg-5`}/>
          </section>
          <section className="home-logos col-12 order-1 order-md-2">
            <div className="bg-white border-bottom border-top">
              <FeaturedClients/>
            </div>
          </section>
        </div>
        <section
          className="home-stack pt-lg-10 pt-9"
          style={{ minHeight: '100vh' }}
        >
          <Stack/>
        </section>
        <section
          className="home-posts pt-lg-10 pt-9 pb-5 pb-lg-0"
          style={{ minHeight: '100vh' }}
        >
          <RecentArticles/>
        </section>
        <section className="home-projects pt-lg-10 pt-9">
          <FeaturedProject/>
        </section>
      </DefaultLayout>
    )
  }
}

export default IndexPage
