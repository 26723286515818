/**
 * This file is part of Keith-mifsud.me
 *
 * @licence Please view the Licence file supplied with this source code.
 *
 * @author Keith Mifsud <https://www.keith-mifsud.me>
 *
 * @copyright Keith Mifsud 2019 <mifsud.k@gmail.com>
 *
 * @since   2.0
 * @version 2.0 Second Major Release
 */

import React, { Component } from 'react'
import { isSmallScreen } from '../../../lib/helpers'
import Renault from './Renault'
import Xerox from './Xerox'
import Hilton from './Hilton'
import SesameBankhallGroup from './SesameBankhallGroup'
import Podpoint from './Podpoint'
import Aviva from './Aviva'

class FeaturedClients extends Component {
  constructor (props) {
    super(props)
    this.state = { isSmallScreen: true }
  }

  componentDidMount () {
    if (typeof window !== 'undefined') {
      if (!isSmallScreen()) {
        this.setState({ isSmallScreen: false })
        this.rotateLogos()
      }
    }
  }

  componentWillUnmount () {
    clearInterval(this.timerID)
  }

  rotateLogos = () => {
    let interval = 1000
    let index = 1
    let allLogos = document.getElementsByClassName('logo')

    this.timerID = setInterval(function () {
      if (index === (allLogos.length + 1)) {
        index = 1
      }

      for (let i = 0; i < allLogos.length; i++) {
        allLogos[i].classList.add('grayscale')
      }

      let highlightLogo = document.getElementById('logo-' + index)
      highlightLogo.classList.remove('grayscale')
      index++
    }, interval)
  }

  render () {
    return (
      <div className={`client-logos`}>
        <div className="d-lg-none">
          <div
            className="carousel slide"
            id="client-logos-carousel"
            data-ride="carousel"
          >
            <div className="carousel-inner p-5 text-center">
              <div className="carousel-item active">
                <Xerox className={`d-block w-100`}/>
              </div>
              <div className="carousel-item">
                <Hilton className={`d-block w-100`}/>
              </div>
              <div className="carousel-item">
                <Renault className={`d-block w-100`}/>
              </div>
              <div className="carousel-item">
                <Aviva className={`d-block w-100`}/>
              </div>
              <div className="carousel-item">
                <SesameBankhallGroup className={`d-block w-100`}/>
              </div>
              <div className="carousel-item">
                <Podpoint className={`d-block w-100`}/>
              </div>
            </div>
          </div>
        </div>
        <div className="container d-none d-lg-block">
          <div className={`row pt-4 pb-4 justify-content-around`}>
            <div className="grayscale logo" id={`logo-1`}>
              <Xerox/>
            </div>
            <div className="grayscale logo" id={`logo-2`}>
              <Hilton/>
            </div>
            <div className="grayscale logo" id={`logo-3`}>
              <Renault/>
            </div>
            <div className="grayscale logo" id={`logo-4`}>
              <Aviva/>
            </div>
            <div className="grayscale logo" id={`logo-5`}>
              <SesameBankhallGroup/>
            </div>
            <div className="grayscale logo" id={`logo-6`}>
              <Podpoint/>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default FeaturedClients
