/**
 * This file is part of Keith-mifsud.me
 *
 * @licence Please view the Licence file supplied with this source code.
 *
 * @author Keith Mifsud <https://www.keith-mifsud.me>
 *
 * @copyright Keith Mifsud 2019 <mifsud.k@gmail.com>
 *
 * @since   1.0
 * @version 1.0 Initial Release
 */

import React, { Component } from 'react'
import { Link } from 'gatsby'
import Coding from './Coding'
import Libraries from './Libraries'
import Tools from './Tools'

class Stack extends Component {
  render () {
    return (
      <div className={`container`}>
        <h2>Recent technology</h2>
        <p>These are some of the programming languages, frameworks and tools I've had the opportunity to work with during <strong>2018</strong>. I'm experienced in several other technologies and I enjoy learning new ones every month.</p>
        <ul className="nav nav-tabs mt-lg-7 mt-5 row" id={`stack`} role={`tablist`}>
          <li className="nav-item col-4">
            <a
              href="#coding"
              className="nav-link pt-4 pb-3 pt-lg-3 pb-lg-3 pt-sm-3 pb-sm-3"
              id={`coding-tab`}
              data-toggle="tab"
              role={`tab`}
              aria-controls={`coding`}
              aria-selected={`false`}
            >Coding</a>
          </li>
          <li className="nav-item col-5">
            <a
              href="#libraries"
              className="nav-link active pt-lg-3 pb-lg-3 pt-sm-3 pb-sm-3"
              id={`libraries-tab`}
              data-toggle="tab"
              role={`tab`}
              aria-controls={`libraries`}
              aria-selected={`true`}
            >Frameworks & Libraries</a>
          </li>
          <li className="nav-item col-3">
            <a
              href="#tools"
              className="nav-link pt-4 pt-sm-3 pb-sm-3 pb-3 pb-lg-3 pt-lg-3"
              id={`tools-tab`}
              data-toggle="tab"
              role={`tab`}
              aria-controls={`tools`}
              aria-selected={`false`}
            >Tools</a>
          </li>
        </ul>
        <div className="tab-content" id={`stackContent`}>
          <div className="tab-pane fade px-2 py-4" id={`coding`} role={`tabpanel`} aria-labelledby={`coding-tab`}>
            <Coding/>
          </div>
          <div className="tab-pane fade show active px-2 py-4" id={`libraries`} role={`tabpanel`} aria-labelledby={`libraries-tab`}>
            <Libraries/>
          </div>
          <div className="tab-pane fade px-2 py-4" id={`tools`} role={`tabpanel`} aria-labelledby={`tools-tab`}>
            <Tools/>
          </div>
        </div>
        <div className={`pt-lg-0 pt-3 pb-lg-0 pb-5`}>
          <div className="text-center pb-lg-0 pb-5">
            <p className="blockquote">“Tell me and I forget, teach me and I may remember, involve me and I learn.” <strong> - Benjamin Franklin</strong></p>

          </div>
          <p className={`text-center mt-lg-7`}><Link to="/contact" className={`btn btn-warning btn-lg`}>Get in touch</Link></p>
        </div>
      </div>
    )
  }
}

export default Stack
