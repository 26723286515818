/**
 * This file is part of Keith-mifsud.me
 *
 * @licence Please view the Licence file supplied with this source code.
 *
 * @author Keith Mifsud <https://www.keith-mifsud.me>
 *
 * @copyright Keith Mifsud 2019 <mifsud.k@gmail.com>
 *
 * @since   1.0
 * @version 1.0 Initial Release
 */
import React, { Component } from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class RecentArticles extends Component {
  render () {
    return (
      <div className={`container`}>
        <h2>Recent articles</h2>
        <div>
          <StaticQuery query={graphql`
            query {
              allMarkdownRemark (
                limit: 3
                sort: {fields: [frontmatter___date], order: DESC}
                filter: {
                  fields:{
                    collection: {eq: "posts"}
                  }
                  frontmatter: {
                                        featured: {eq: true}
                                      }
                  }
            ){
                edges{
                  node{
                    timeToRead
                    excerpt
                    frontmatter{
                      cover_image{
                        publicURL
                        childImageSharp{
                          fluid {
                            ...GatsbyImageSharpFluid
                          }
                        }
                      }
                      date(formatString: "Do MMMM, YYYY")
                      title
                      path
                      tags
                      featured
                    }
                  }
                }
              }
            }
          `}
          render={data =>
            <div className={`row`}>
              {data.allMarkdownRemark.edges.map(({ node }) => (
               <div key={node.frontmatter.title} className={`col-12 col-md-4 pt-3 pt-sm-5`}>
                 <div className={`card h-100 border rounded`}>
                   <Link to={node.frontmatter.path}>
                     <Img fluid={node.frontmatter.cover_image.childImageSharp.fluid} className={`img-fluid card-img-top`}/>
                   </Link>

                   <div className="card-body">
                     <Link to={node.frontmatter.path} className={`hover-none hover-lighten`} >
                       <h3 className={`card-title hover-lighten`} style={{ minHeight: '4rem' }}>{node.frontmatter.title}</h3>
                     </Link>
                     <small className="text-muted">
                       Published on {node.frontmatter.date}
                     </small>
                     <div className="tags pb-3 pt-3">
                       {node.frontmatter.tags.map(tag =>
                         <span key={tag} className={`badge badge-secondary mr-2`}>{tag}</span>
                       )}
                     </div>
                     <p className={`card-text text-secondary`} style={{ minHeight: '8rem' }}>
                       {node.excerpt}
                     </p>
                   </div>
                   <div className="card-footer bg-white border-0 text-right">
                     <small className={`text-muted`}><FontAwesomeIcon icon={['far', 'clock']}/> {node.timeToRead} min read</small>
                   </div>
                 </div>
               </div>
              ))}
            </div>
          }
          />
        </div>
        <div className="row justify-content-end text-right pt-4 pb-lg-4">
          <div className="col-lg-4 col-5">
            <Link to="/blog" className={`btn btn-secondary btn-lg`}>Blog <FontAwesomeIcon icon={['fas', 'chevron-right']}/></Link>
          </div>
        </div>
      </div>
    )
  }
}

export default RecentArticles
