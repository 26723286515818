/**
 * This file is part of Keith-mifsud.me
 *
 * @licence Please view the Licence file supplied with this source code.
 *
 * @author Keith Mifsud <https://www.keith-mifsud.me>
 *
 * @copyright Keith Mifsud 2019 <mifsud.k@gmail.com>
 *
 * @since   1.0
 * @version 1.0 Initial Release
 */

import React, { Component } from 'react'
import LearnK8s from './LearnK8s'
import GreenstreetBerman from './GreenstreetBerman'
import WoodenFurnitureStore from './WoodenFurnitureStore'
import PrCreate from './PrCreate'
import VampireVape from './VampireVape'
import HirolaGroup from './HirolaGroup'

class Testimonials extends Component {
  render () {
    return (
      <div className={ `container ${ this.props.className }`}>
        <div className={`row`}>
          <div className="col-md-4 order-1">
            <HirolaGroup/>
          </div>
          <div className="col-md-4 pt-md-0 pt-3 order-3">
            <LearnK8s/>
          </div>
          <div className="col-md-4 pt-md-0 pt-3 order-2">
            <GreenstreetBerman/>
          </div>
        </div>
        <div className={`card-deck`} style={ { display: 'none' } }>
          <WoodenFurnitureStore/>
          <PrCreate/>
        </div>
        <div className={`card-deck`} style={ { display: 'none' } }>
          <GreenstreetBerman/>
          <VampireVape/>
        </div>
      </div>
    )
  }
}

export default Testimonials
