/**
 * This file is part of Keith-mifsud.me
 *
 * @licence Please view the Licence file supplied with this source code.
 *
 * @author Keith Mifsud <https://www.keith-mifsud.me>
 *
 * @copyright Keith Mifsud 2019 <mifsud.k@gmail.com>
 *
 * @since   1.0
 * @version 1.0 Initial Release
 */

import React, { Component } from 'react'
import { graphql, StaticQuery } from 'gatsby'
import Img from 'gatsby-image'

class GreenstreetBerman extends Component {
  render () {
    return (
      <div className={`card h-100 ${ this.props.className }`}>
        <div className="card-body text-center">
          <StaticQuery
            query={graphql`
              query {
                placeholderImage: file(relativePath: { eq: "testimonials/trevor-stockwell.png" }) {
                  childImageSharp {
                    fixed (height: 100){
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            `}
            render={data =>
              <Img fixed={data.placeholderImage.childImageSharp.fixed} className={`rounded-circle mb-4`}/>}
          />
          <p className="card-text pt-lg-7">
            Keith is very knowledgeable on coding and being coupled with the quality of being meticulous equates to a very good end result.
          </p>
        </div>
        <div className="card-footer bg-transparent border-0 text-center">
          <strong className="card-title">
            Trevor Stockwell - Greenstreet Berman
          </strong>
        </div>
      </div>
    )
  }
}

export default GreenstreetBerman
