/**
 * This file is part of Keith-mifsud.me
 *
 * @licence Please view the Licence file supplied with this source code.
 *
 * @author Keith Mifsud <https://www.keith-mifsud.me>
 *
 * @copyright Keith Mifsud 2019 <mifsud.k@gmail.com>
 *
 * @since   1.0
 * @version 1.0 Initial Release
 */

import React, { Component } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

class Podpoint extends Component {
  render () {
    return (
      <div className={` ${this.props.className} `}>
        <StaticQuery
          query={graphql`
            query {
              placeholderImage: file(relativePath: { eq: "clients/podpoint-logo.png" }) {
                childImageSharp {
                  fixed (height: 60){
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          `}
          render={data =>
            <Img fixed={data.placeholderImage.childImageSharp.fixed} />}
        />
      </div>
    )
  }
}

export default Podpoint
