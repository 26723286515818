/**
 * This file is part of Keith-mifsud.me
 *
 * @licence Please view the Licence file supplied with this source code.
 *
 * @author Keith Mifsud <https://www.keith-mifsud.me>
 *
 * @copyright Keith Mifsud 2019 <mifsud.k@gmail.com>
 *
 * @since   1.0
 * @version 1.0 Initial Release
 */
import React, { Component } from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class FeaturedProject extends Component {
  render () {
    return (
      <div className={`container`}>
        <h2>Open source</h2>
        <div>
          <StaticQuery query={graphql`
            query {
              allMarkdownRemark (
                limit: 1
                sort: {fields: [frontmatter___date], order: DESC}
                filter: {
                    fields: {
                        collection: {eq: "projects"}
                    }
                    frontmatter: {
                        featured: {eq: true}
                    }
                }
              ) {
                edges{
                  node{
                    frontmatter{
                      cover_image{
                        publicURL
                        childImageSharp{
                          fixed(height: 340) {
                            ...GatsbyImageSharpFixed
                          }
                        }
                      }
                      date(formatString: "Do MMMM, YYYY")
                      updated(formatString: "Do MMMM, YYYY")
                      title
                      path
                    }
                  }
                }
              }
            }
          `}
                       render={data =>
                         <div className={`row mt-lg-5`}>
                           {data.allMarkdownRemark.edges.map(({ node }) => (
                             <div key={node.frontmatter.path} className={`col-12 px-4`}>
                               <div className="row border rounded py-3 pt-2">
                                 <div className={`col-12 col-lg-7 col-sm-6 pt-lg-3 pl-4 order-sm-1 order-2`}>
                                   <Link to={node.frontmatter.path} className={`hover-none`}>
                                     <h3 className={`hover-lighten pt-sm-0 pt-3`}>{node.frontmatter.title}</h3>
                                   </Link>
                                   <div>
                                     <p>
                                       <small className="text-muted">First released on {node.frontmatter.date}</small> | <small className="text-muted">Last updated on {node.frontmatter.updated}</small>
                                     </p>
                                   </div>
                                   <div className={`pb-4 pb-lg-4 pb-sm-0`}>
                                     <img src="https://travis-ci.org/keithmifsud/jekyll-target-blank.svg?branch=master"
                                          alt=""/> <a href="https://badge.fury.io/rb/jekyll-target-blank" target="_blank"><img
                                     src="https://badge.fury.io/rb/jekyll-target-blank.svg" alt="Gem Version"
                                     height="18"/></a>
                                   </div>
                                   <p className={`pt-4 small-sm`}>
                                     A Jekyll plugin to facilitate implementing the <span
                                     className="font-italic">target</span> attribute of external links. Includes several
                                     configuration options such as default and custom <span
                                     className={`font-italic`}>rel</span> attributes, adding custom CSS classes and even
                                     overriding default behaviour as needed. Works with HTML, Markdown and Liquid tags.
                                   </p>
                                 </div>
                                 <div className="col-12 col-lg-5 col-sm-6 text-md-right text-center order-sm-2 order-1">
                                   <Link to={node.frontmatter.path}>
                                     <Img fixed={node.frontmatter.cover_image.childImageSharp.fixed}
                                          className={`img-fluid card-img-top rounded`}/>
                                   </Link>
                                 </div>
                               </div>
                             </div>
                           ))}
                         </div>
                       }

          />
        </div>
        <div className="row justify-content-end text-right pt-4 pb-lg-4">
          <div className="col-lg-4 col-5">
            <Link to="/projects" className={`btn btn-secondary btn-lg`}>Projects <FontAwesomeIcon icon={['fas', 'chevron-right']}/></Link>
          </div>
        </div>
      </div>
    )
  }
}

export default FeaturedProject
