/**
 * This file is part of Keith-mifsud.me
 *
 * @licence Please view the Licence file supplied with this source code.
 *
 * @author Keith Mifsud <https://www.keith-mifsud.me>
 *
 * @copyright Keith Mifsud 2019 <mifsud.k@gmail.com>
 *
 * @since   2.0
 * @version 2.0 Second Major Release
 */

import React, { Component } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Icon from '../Icon'

class Libraries extends Component {
  createIconComponent = (iconType, iconName) => {
    let icon
    if (iconType === 'fa') {
      icon = <FontAwesomeIcon icon={[iconType, iconName]}/>
    }
    if (iconType === 'fab') {
      icon = <FontAwesomeIcon icon={[iconType, iconName]}/>
    }
    if (iconType === 'devicon') {
      let name = iconName
      icon = <i className={`${ name }`}></i>
    }
    if (iconType === 'svg') {
      icon = <Icon
        type={iconName}
        style={ { height: '32px', display: 'inline' } }
      />
    }
    return icon
  }

  render () {
    return (
      <div>
        <StaticQuery
          query={graphql`
            query {
              allLibrariesJson (
                filter: {featured: {eq: true}}
              ) {
                edges{
                  node{
                    name
                    featured
                    icon
                    iconType
                  }
                }
              }
            }
          `}
          render={data =>
            <div className="row pt-2">
              {data.allLibrariesJson.edges.map (({ node }) => (
                <div key={node.name} className={`col-4 col-lg-2 pt-2 col-sm-2`}>
                  <div className={`d-inline-flex`}>
                    { node.icon != null &&
                      node.iconType != null &&
                    <span className={ `icon-container` }>
                      {this.createIconComponent(node.iconType, node.icon)}
                    </span>
                    }
                    <small className={`pl-2 pt-3 ${ node.name }`}>{node.name}</small>
                  </div>
                </div>
              ))}
            </div>
          }
        />
      </div>
    )
  }
}

export default Libraries
