/**
 * This file is part of Keith-mifsud.me
 *
 * @licence Please view the Licence file supplied with this source code.
 *
 * @author Keith Mifsud <https://www.keith-mifsud.me>
 *
 * @copyright Keith Mifsud 2019 <mifsud.k@gmail.com>
 *
 * @since   2.0
 * @version 2.0 Second MajorRelease
 */

import React, { Component } from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import Icon from '../Icon'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class FeaturedServices extends Component {
  render () {
    return (
      <div className={`container pt-lg-10 pt-8`}>
        <div className="row">
          <div className="col-lg-6 col-12">
            <h2>I can help you with ...</h2>
          </div>
        </div>
        <div>
          <StaticQuery
            query={graphql`
              query {
                  allMarkdownRemark (
                    sort: {fields: [frontmatter___order], order: ASC}
                    filter: {
                      fields:{
                        collection: {eq: "services"}
                      }
                    }
                  ) {
                    edges{
                      node{
                        id
                        frontmatter{
                          icon
                          title
                          path
                          description
                          order
                          homepage
                        }
                      }
                    }
                  }
              }
            `}
            render={data =>

                <div className="row">
                {data.allMarkdownRemark.edges.map(({ node }) => (
                  node.frontmatter.homepage ? (
                    <div key={node.id} className={`col-12 col-lg-4 pt-lg-8 pt-5`}>
                      <Icon type={node.frontmatter.icon} className={`pb-4`}/>
                      <Link to={node.frontmatter.path} className={`hover-none`}>
                        <h3 className={`hover-lighten`}>{node.frontmatter.title}</h3>
                      </Link>
                      <p className={`text-secondary`}>{node.frontmatter.description}</p>
                      <p><small className="text-muted">
                        <Link
                          to={node.frontmatter.path}
                          title={node.frontmatter.title}
                          className={`hover-none hover-darken`}
                        >
                          more details ...
                        </Link>
                      </small></p>
                    </div>
                    ) : (null)
                ))}
                </div>


            }
          />
        </div>
        <div className="row justify-content-end text-right pt-8 pb-6">
          <div className="col-lg-4 col-6">
            <Link to="/services" className={`btn btn-secondary btn-lg`}>Services <FontAwesomeIcon icon={['fas', 'chevron-right']}/></Link>
          </div>
        </div>
      </div>
    )
  }
}

export default FeaturedServices
