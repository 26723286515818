/**
 * This file is part of Keith-mifsud.me
 *
 * @licence Please view the Licence file supplied with this source code.
 *
 * @author Keith Mifsud <https://www.keith-mifsud.me>
 *
 * @copyright Keith Mifsud 2019 <mifsud.k@gmail.com>
 *
 * @since   2.0
 * @version 2.0 Second Major Release
 */

import React, { Component } from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import AnchorLink from 'react-anchor-link-smooth-scroll'

class Hero extends Component {
  render () {
    return (
      <div
        className={this.props.className}
      >
        <StaticQuery
          query={graphql`
            query {
              placeholderImage: file(relativePath: { eq: "hero1-bg.png" }) {
                childImageSharp {
                  fluid(maxWidth: 2200) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          `}
          render={data =>
            <Img
              fluid={data.placeholderImage.childImageSharp.fluid}
              style={{
                position: 'absolute',
                right: 0,
                bottom: 0,
                width: '80%',
                height: '90%'
              }}
              className={`hero-image`}
            />}
        />
        <div className="container">
          <div className={`col-12 col-lg-7 col-sm-9 pt-md-0 pt-sm-9 pt-md-17 pt-xl-8 welcome-message`}>
            <h1>
              Hi, I'm Keith, an independent software engineer. I solve business challenges with code, design,
              infrastructure and teams.
            </h1>
            <div className={`button-container pt-lg-4 row no-gutters pt-6 pt-sm-3 pt-md-6`}>
              <div className="col-12 col-md-auto col-sm-5">
                <Link to="/contact" className={`btn btn-warning btn-lg`}>
                  Get in touch
                </Link>
              </div>
              <div className="col-12 col-md-auto col-sm-5 pt-3 pt-sm-0">
                <AnchorLink href={`#start-here`}>
                  <button className={`btn btn-outline-secondary btn-lg`} style={{ maxHeight: '48px' }}>
                    Start here <FontAwesomeIcon icon={faChevronDown} style={{ fontSize: '0.875rem' }}/>
                  </button>
                </AnchorLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Hero
